import { Text, View } from '@react-pdf/renderer';
import { styles } from './table-styles';
import { PDF_COLOR, PDF_LETTET_BLANK, PDF_COMPOSITION_LETTET_BLANK } from '../../../constants/style';

const Body = ({ values }: any) => {
  return (
    <View>
      {values.map((obj: any, rowIndex: number) => {
        if (obj.isHidden) return <></>;
        if (obj.category) {
          return (
            // CATEGORY
            <View
              style={[
                styles.col,
                styles.colCategory,
                {
                  backgroundColor: PDF_COLOR['background'][obj.style.backgroundColor],
                },
              ]}
            >
              <Text style={[{ paddingLeft: 1 }]}>{obj.title}</Text>
            </View>
          );
        } else {
          const valueObj = obj.value;
          return (
            // MENU
            <>
              <View style={styles.row} key={rowIndex} wrap={false}>
                {Object.keys(valueObj).map((key, colIndex) => {
                  return (
                    // VALUE
                    <View
                      style={[
                        styles.col,
                        styles.colValue,
                        colIndex === 0 ? styles.colNameValue : {},
                        {
                          ...valueObj[key]?.style,
                          width: valueObj[key].style?.width + '%',
                        },
                      ]}
                      key={colIndex}
                    >
                      <Text
                        style={{
                          marginLeft: colIndex === 0 ? PDF_LETTET_BLANK * valueObj[key]?.blank || 0 : 0,
                        }}
                      >
                        {valueObj[key].value}
                      </Text>
                    </View>
                  );
                })}
              </View>
              {obj.compositions.length > 0 ? (
                // COMPOSITIONS
                <View>
                  {obj.compositions.map((composition: any) => {
                    const isGroupRecord = composition.type === 'group';
                    const compositionValueObj = composition.value;
                    if (isGroupRecord) {
                      return (
                        // GROUP COMPOSITION
                        <View style={styles.row} key={rowIndex} wrap={false}>
                          {Object.keys(compositionValueObj).map((key, colIndex) => {
                            return (
                              // VALUE
                              <View
                                style={[
                                  styles.col,
                                  styles.colValue,
                                  colIndex === 0 ? styles.colNameValue : {},
                                  {
                                    ...compositionValueObj[key]?.style,
                                    width:
                                      colIndex === 0
                                        ? compositionValueObj[key].style?.width * 2 + '%'
                                        : compositionValueObj[key].style?.width + '%',
                                  },
                                ]}
                                key={colIndex}
                              >
                                <Text
                                  style={{
                                    marginLeft: colIndex === 0 ? PDF_COMPOSITION_LETTET_BLANK : 0,
                                  }}
                                >
                                  {compositionValueObj[key].value}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      );
                    }
                    return <></>;
                  })}
                </View>
              ) : (
                <></>
              )}
            </>
          );
        }
      })}
    </View>
  );
};

export { Body };
