import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import { forwardRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../style.scss';

interface DatePickerCustomProps {
  onChange?: (date: Date) => void;
  value: Date | null;
  name?: string;
  maxDate?: Date | null;
  minDate?: Date | null;
  isInvalid?: boolean;
  errors?: any;
  disabled?: boolean;
  index?: number;
}

const DatePickerCustom: React.FC<DatePickerCustomProps> = (props) => {
  const [selectedDate, setSelectedDate] = useState(props.value);
  const years = range(1990, 2100, 1);
  const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);
    if (props.onChange) {
      props.onChange(date);
    }
  };

  return (
    <>
      <DatePicker
        className="form-control"
        dateFormat="yyyy/MM/dd"
        locale={'ja'}
        maxDate={props.maxDate ?? new Date('2099/12/31')}
        minDate={props.minDate ?? new Date('1990/01/01')}
        //showIcon
        //icon={<FaCalendarAlt />}
        customInput={<CustomInputDatePicker isInvalid={props.isInvalid} errors={props.errors} index={props.index} />}
        toggleCalendarOnIconClick
        selected={selectedDate}
        onChange={handleChangeDate}
        name={props.name}
        popperContainer={({ children }) => <div>{children}</div>} // Renders outside table constraints
        popperPlacement="auto" // Adjust placement to fit
        disabled={props.disabled}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              className="btn btn-sm btn-primary"
              style={{ minWidth: '40px', backgroundColor: 'transparent', border: 'none' }}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {!prevMonthButtonDisabled && <FaChevronLeft size={14} className="fa-chevron-icon" />}
            </button>

            <Form.Select
              className="form-select form-select-sm"
              size="sm"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>

            <Form.Select
              className="form-select form-select-sm"
              size="sm"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(parseInt(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>

            <button
              className="btn btn-sm btn-primary"
              style={{ minWidth: '40px', backgroundColor: 'transparent', border: 'none' }}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {!nextMonthButtonDisabled && <FaChevronRight size={14} className="fa-chevron-icon" />}
            </button>
          </div>
        )}
      />
    </>
  );
};

const CustomInputDatePicker = forwardRef((props: any, ref: any) => {
  // You can style this further
  return (
    <>
      <div className="position-relative">
        <FaCalendarAlt
          style={{ position: 'absolute', top: '0.7rem', left: '0.8rem', cursor: 'pointer' }}
          onClick={props.onClick}
        />
        <Form.Control
          className={props.className}
          onClick={props.onClick}
          style={{ paddingLeft: '32px' }}
          value={props.value}
          readOnly={true}
          type="text"
          isInvalid={props.isInvalid}
          name={props.name}
        />
        {props.isInvalid && (
          <Form.Control.Feedback key={props.index} type="invalid">
            {props.errors}
          </Form.Control.Feedback>
        )}
      </div>
    </>
  );
});

export default DatePickerCustom;
