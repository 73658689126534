import { Button, Col, Form, FormControl, InputGroup, Row, Stack } from 'react-bootstrap';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../Service';
import '../style.scss';
import { ElementConfigurationSettingList, NutritionMaster } from '../models/model';
import {
  ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS,
  SPECIFIC_RAW_MATERIALS,
  PENDING_CONFIRMATION,
  PENDING_APPROVAL,
} from '../constant';
import ClassificationItemSelect from '../components/SelectClassificationMaster';

const COLUMN_WIDTH = [40, 70, 60, 115, 90, 180, 85, 200, 270, 110, 100, 110, 100];

function PartsConfigurationList() {
  const { t } = useTranslation();
  const history = useHistory();
  //state
  const [data, setData] = useState<Array<ElementConfigurationSettingList>>([]);
  const [nutritions, setNutritions] = useState<NutritionMaster[]>([]);

  type StatusType = 'all' | typeof PENDING_CONFIRMATION | typeof PENDING_APPROVAL;
  type PeriodType = 'all' | 'valid' | 'invalid';
  type NotificationType = 'all' | 'changed' | 'notChanged';

  // enum NotificationText {
  //   Unregistered = '未登録',
  //   Changed = '変更有',
  //   Registered = '',
  // }

  //ref
  const searchRef = useRef<string>('');
  const nextPageRef = useRef<string | null>(null);
  const prevPageRef = useRef<string | null>(null);
  const pageRef = useRef<number>(1);
  const totalPageRef = useRef<number>(0);
  const pageCurrentElement = useRef<HTMLInputElement>(null);
  const selectedElementRef = useRef<string[]>([]);
  const checkBoxRefs = useRef<HTMLInputElement[]>([]);

  const statusRef = useRef<StatusType>('all');
  const periodRef = useRef<PeriodType>('all');
  const notificationRef = useRef<NotificationType>('all');
  const elementClassificationRef = useRef<string>('all');

  const fetchUrl = '/parts-configuration-setting';

  //useEffect
  useEffect(() => {
    fetchNutritions();
    fetchData();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      resetCheckBox(checkBoxRefs, selectedElementRef);
    }
  }, [data]);

  const resetCheckBox = (
    checkBoxRefs: React.MutableRefObject<HTMLInputElement[]>,
    selectedElementRef: React.MutableRefObject<string[]>
  ) => {
    if (checkBoxRefs.current.length > 0 && selectedElementRef.current.length > 0) {
      checkBoxRefs.current.forEach((checkBox) => {
        if (checkBox) {
          checkBox.checked = false;
          (checkBox.parentElement?.parentElement?.parentElement as HTMLElement).classList.toggle(
            'div-tr-selected',
            false
          );
        }
      });
      checkBoxRefs.current = [];
      selectedElementRef.current = [];
    }
  };

  // function
  const fetchNutritions = async () => {
    try {
      const res = await api.getAllNutritions();
      setNutritions(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  //handler
  const handleClickSearch = () => {
    pageRef.current = 1;
    fetchData();
  };

  const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchRef.current = event.target.value;
    pageRef.current = 1;
  };

  // area table 1
  const fetchData = async () => {
    try {
      const params = {
        page: pageRef.current.toString(),
        search: searchRef.current,
        status: statusRef.current,
        period: periodRef.current,
        notification: notificationRef.current,
        classification: elementClassificationRef.current,
      };
      const url = `${fetchUrl}?${new URLSearchParams(params).toString()}`;
      const response = await api.getAll(url);
      const data = response.results;
      setData(data);
      totalPageRef.current = Math.ceil(response.count / response.page_size);
      if (pageCurrentElement.current) {
        pageRef.current = response.page_current;
        pageCurrentElement.current.value = pageRef.current.toString();
      }
      nextPageRef.current = response.next;
      prevPageRef.current = response.previous;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleNextPage = () => {
    if (nextPageRef.current) {
      const url = new URL(nextPageRef.current);
      pageRef.current = parseInt(url.searchParams.get('page') || '1');
      fetchData();
    }
  };

  const handlePrevPage = () => {
    if (prevPageRef.current) {
      const url = new URL(prevPageRef.current);
      // get page from url and set to ref
      pageRef.current = parseInt(url.searchParams.get('page') || '1');
      fetchData();
    }
  };

  const handleEnterToPage = () => {
    const currentPage = pageCurrentElement.current?.value;
    if (currentPage && !isNaN(parseInt(currentPage))) {
      const pageNumber = parseInt(currentPage);
      if (pageNumber >= 1 && pageNumber <= totalPageRef.current) {
        pageRef.current = pageNumber;
        fetchData();
      }
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const index = selectedElementRef.current.indexOf(value);
    if (checked && index === -1) {
      selectedElementRef.current.push(value);
    } else {
      selectedElementRef.current.splice(index, 1);
    }
    (event.target.parentElement?.parentElement?.parentElement as HTMLElement).classList.toggle(
      'div-tr-selected',
      checked
    );
  };

  const handleInputElementClassification = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    elementClassificationRef.current = value === undefined ? 'all' : value;
    pageRef.current = 1;
    fetchData();
  };

  const handleInputStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === PENDING_APPROVAL || value === PENDING_CONFIRMATION) {
      statusRef.current = value;
    } else {
      statusRef.current = 'all';
    }
    pageRef.current = 1;
    fetchData();
  };

  const handleInputPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as PeriodType;
    periodRef.current = value === undefined ? 'all' : value;
    pageRef.current = 1;
    fetchData();
  };

  const handleInputNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as NotificationType;
    notificationRef.current = value === undefined ? 'all' : value;
    pageRef.current = 1;
    fetchData();
  };

  const handleOpenEditPage = () => {
    if (selectedElementRef.current.length !== 1) {
      return;
    }
    const element_cd = selectedElementRef.current[0];
    history.push(`/calorie-management/parts-configuration-settings/edit/${element_cd}`);
  };

  const handleOpenAddNewPage = () => {
    history.push('/calorie-management/parts-configuration-settings/new');
  };

  const handleOpenCopyPage = () => {
    if (selectedElementRef.current.length !== 1) {
      return;
    }
    const element_cd = selectedElementRef.current[0];
    history.push(`/calorie-management/parts-configuration-settings/copy/${element_cd}`);
  };

  // const notificationText = (elm: ElementConfigurationSettingList): NotificationText => {
  //   if (elm.is_all_invalid_effective_date) {
  //     return NotificationText.Unregistered;
  //   }

  //   const hasCompositionChanged =
  //     elm.is_nested_element_composition_changed || elm.is_over_suspend_date || elm.is_any_invalid_effective_date;

  //   if (elm.change_flg || hasCompositionChanged) {
  //     return NotificationText.Changed;
  //   }

  //   return NotificationText.Registered;
  // };

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-2 border-bottom">
        <Row className="align-items-center">
          <Col xs="4">
            <h4 className="mb-0 fw-bold">{t('PartsConfigurationList.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button className="fs-5 fw-bold text-muted btn-sm" variant="secondary" onClick={handleOpenEditPage}>
                {t('PartsConfigurationList.edit')}
              </Button>
              <Button className="fs-5 fw-bold btn-sm" onClick={handleOpenCopyPage}>
                {t('PartsConfigurationList.copy_and_register')}
              </Button>
              <Button className="fs-5 fw-bold btn-sm" onClick={handleOpenAddNewPage}>
                {t('PartsConfigurationList.register')}
              </Button>
              <Button className="fs-5 fw-bold btn-sm" onClick={() => history.goBack()}>
                {t('PartsConfigurationList.go_back')}
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* filter */}
      <div className="py-1">
        <Row className="align-items-center gap-4 mb-1 mb-xs-0">
          <Col xs="auto" sm="5" md="2">
            <InputGroup>
              <FormControl
                className="border-end-0"
                placeholder={t('PartsConfigurationList.name_or_code_search')}
                aria-label="Search"
                onChange={handleInputSearch}
              />
              <InputGroup.Text className="bg-transparent border-start-0 cursor-pointer" onClick={handleClickSearch}>
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col xs="auto" className="d-flex">
            <Form.Label className="me-2 mb-0 text-nowrap align-self-center">
              {t('PartsConfigurationList.element_classification')}
            </Form.Label>
            <ClassificationItemSelect
              value={elementClassificationRef.current}
              onChange={handleInputElementClassification}
              readonly={false}
              showFirstOption={true}
              id="element_classification"
              name="element_classification"
            />
          </Col>
        </Row>
        <Row className="align-items-center gap-4">
          <Col xs="auto">{t('PartsConfigurationList.status')}</Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label={t('PartsConfigurationList.all')}
                  name="group_status"
                  type="radio"
                  defaultChecked
                  id="group_status-all"
                  onChange={handleInputStatus}
                  defaultValue={'all'}
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label={t('PartsConfigurationList.pending_confirmation')}
                  name="group_status"
                  type="radio"
                  id="group_status-pending_confirmation"
                  onChange={handleInputStatus}
                  defaultValue={PENDING_CONFIRMATION}
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label={t('PartsConfigurationList.pending_approval')}
                  name="group_status"
                  type="radio"
                  id="group_status-pending_approval"
                  onChange={handleInputStatus}
                  defaultValue={PENDING_APPROVAL}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="auto">{t('PartsConfigurationList.notification')}</Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label={t('PartsConfigurationList.all')}
                  name="group_notification"
                  type="radio"
                  defaultChecked
                  id="group-notification-all"
                  onChange={handleInputNotification}
                  defaultValue={'all'}
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label={t('PartsConfigurationList.changed_only')}
                  name="group_notification"
                  type="radio"
                  id="group_notification-changed"
                  onChange={handleInputNotification}
                  defaultValue={'changed'}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="auto">{t('PartsConfigurationList.application_period')}</Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label={t('PartsConfigurationList.all')}
                  name="group_application_period"
                  type="radio"
                  defaultChecked
                  id="group_application_period-all"
                  onChange={handleInputPeriod}
                  defaultValue={'all'}
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label={t('PartsConfigurationList.validity_period_only')}
                  name="group_application_period"
                  type="radio"
                  id="group_application_period-valid"
                  onChange={handleInputPeriod}
                  defaultValue={'valid'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <!-- Table new status--> */}
      {/* Pagination */}
      <div className="py-1">
        <div className="anra-pagination d-inline-flex flex-row gap-1 align-items-center">
          <Button size="sm" variant="primary" className="btn--auto me-1" onClick={handlePrevPage}>
            <TfiAngleLeft size={16} />
          </Button>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleEnterToPage();
            }}
          >
            <Form.Control
              size="sm"
              type="text"
              ref={pageCurrentElement}
              defaultValue={pageRef.current}
              className="d-inline-block text-center"
              style={{ width: '5rem' }}
              disabled
            />
          </Form>
          <span>/{totalPageRef.current}ページ</span>
          <Button size="sm" variant="primary" className="btn--auto ms-1" onClick={handleNextPage}>
            <TfiAngleRight size={16} />
          </Button>
          <div className="text-start ms-3 text-primary fw-bold lh-1">
            {t('PartsConfigurationList.note_1')}
            <br />
            {t('PartsConfigurationList.note_2')}
          </div>
        </div>
      </div>

      {/* list */}
      <div className="sticky-table parts-configuration-table">
        <div className="div-table fixsize-column">
          <div className="div-thead div-tr">
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[0]}px` }}
            >
              {t('PartsConfigurationList.table.selection')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[1]}px` }}
            >
              {t('PartsConfigurationList.table.status')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[2]}px` }}
            >
              {t('PartsConfigurationList.table.notification')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[3]}px` }}
            >
              {t('PartsConfigurationList.table.business_format')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center lh-1"
              style={{ width: `${COLUMN_WIDTH[4]}px` }}
            >
              <span dangerouslySetInnerHTML={{ __html: t('PartsConfigurationList.table.element_code') }} />
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[5]}px` }}
            >
              {t('PartsConfigurationList.table.name')}
            </div>
            {nutritions &&
              nutritions.map((nutrition, idx) => {
                return (
                  <div
                    className="div-th d-flex align-items-center justify-content-center lh-1"
                    style={{ width: `${COLUMN_WIDTH[6]}px` }}
                    key={idx}
                  >
                    {nutrition.nutrition_name} <br></br> ({nutrition.nutrition_unit_name})
                  </div>
                );
              })}
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[7]}px` }}
            >
              {t('PartsConfigurationList.table.specific_raw_materials')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[8]}px` }}
            >
              {t('PartsConfigurationList.table.items_equivalent_to_specific_raw_materials')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[9]}px` }}
            >
              {t('PartsConfigurationList.table.last_update_by')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[10]}px` }}
            >
              {t('PartsConfigurationList.table.last_update_date')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[11]}px` }}
            >
              {t('PartsConfigurationList.table.confirmed_by')}
            </div>
            <div
              className="div-th d-flex align-items-center justify-content-center"
              style={{ width: `${COLUMN_WIDTH[12]}px` }}
            >
              {t('PartsConfigurationList.table.confirmed_date')}
            </div>
          </div>

          {data &&
            data.map((elm: ElementConfigurationSettingList, idx) => {
              return (
                <div className="div-tr" key={idx}>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[0]}px` }}
                  >
                    <Form.Check
                      className="anra-checkbox d-flex justify-content-center"
                      value={elm.element_cd.toString()}
                      label=""
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      defaultChecked={false}
                      ref={(element: HTMLInputElement) => {
                        checkBoxRefs.current[idx] = element;
                      }}
                    />
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[1]}px` }}
                  >
                    {elm.status}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center text-danger"
                    style={{ width: `${COLUMN_WIDTH[2]}px` }}
                  >
                    {elm.notification_text}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[3]}px` }}
                  >
                    {elm.business_format_name}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[4]}px` }}
                  >
                    {elm.element_cd}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-start"
                    style={{ width: `${COLUMN_WIDTH[5]}px` }}
                  >
                    &nbsp;{elm.element_name}
                  </div>
                  {nutritions &&
                    nutritions.map((nutrition, idx) => {
                      const nutritionsAmount = elm.purchase_nutrition_master_summary?.find(
                        (item) => item.nutrition_cd === nutrition.nutrition_cd
                      );
                      return (
                        <div
                          className="div-td bg-yellow d-flex align-items-center justify-content-start"
                          style={{ width: `${COLUMN_WIDTH[6]}px` }}
                          key={idx}
                        >
                          &nbsp;{nutritionsAmount ? nutritionsAmount.nutrition_amount : ''}
                        </div>
                      );
                    })}
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[7]}px` }}
                  >
                    {elm.purchase_item_allergy_master_summary &&
                      elm.purchase_item_allergy_master_summary
                        .filter((item) => item.allergy_master.type_name === SPECIFIC_RAW_MATERIALS)
                        .map(
                          (item) =>
                            `${item.allergy_master.allergy_name} (${item.allergy_item_master.allergy_item_name})`
                        )
                        .join(', ')}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[8]}px` }}
                  >
                    {elm.purchase_item_allergy_master_summary &&
                      elm.purchase_item_allergy_master_summary
                        .filter((item) => item.allergy_master.type_name === ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS)
                        .map(
                          (item) =>
                            `${item.allergy_master.allergy_name} (${item.allergy_item_master.allergy_item_name})`
                        )
                        .join(', ')}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[9]}px` }}
                  >
                    {elm.applicant_name}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[10]}px` }}
                  >
                    {elm.applied_date}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[11]}px` }}
                  >
                    {elm.verifier_name}
                  </div>
                  <div
                    className="div-td bg-yellow d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[12]}px` }}
                  >
                    {elm.verify_date}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default PartsConfigurationList;
