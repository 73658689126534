import { ApproveButtonResource } from 'components/formfields/Privilege/models/viewApproveButtonprivilege';
import ApproveGroupButton from 'features/ApproveGroupButton';
import { IntroductionFrame } from './components/introductionFrame';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './style.scss';
import { multiSelectTo, mutliDragAwareReorder } from 'utils/dragutils/utils';
import {
  genWidthGroupHeader,
  GroupHeaderValueTypeInterface,
  HeaderValueTypeInterface,
  mappingGroupHeaderStyleToHeader,
  otherColWidthCal,
  remainWidthAfterSelection,
} from 'features/PDF/components/api/api-header';
import { PDF_BACKGROUND_COLOR, PDF_COL_STYLE_TABLE_BY_TYPE, PDF_COLOR } from 'features/PDF/constants/style';
import { pdf } from '@react-pdf/renderer';
import { CaloryPDF } from 'features/PDF/components/CaloryPDF';
import { createPortal } from 'react-dom';
import AddMenuChartModal from './components/AddMenuChartModal/addMenuChartModal';
import NoteChartModal from './components/NoteChartModal/noteChartModal';
import { CategoryRow } from './components/CategoryRow/categoryRow';
import { MenuCompositionRow } from './components/MenuCompositionRow/menuCompositionRow';
import { MenuRow } from './components/MenuRow/menuRow';
import { styles } from './styles';
import { ManualTextNormal } from 'features/PDF/components/common/text';
import cloneDeep from 'lodash/cloneDeep';
import { GROUP_HEADER } from 'features/PDF/constants/group-header';
import { GROUP_HEADER_TYPE } from 'features/PDF/constants/type';
import _ from 'lodash';
import { useIsAllowEdit } from 'components/formfields/Privilege/components/editForm';
import { toast } from 'react-toastify';
import AllergyCaloryChartService from '../../allergyCalorieSettingService';
import nutritionMasterService from 'features/MasterDataManagement/NutritionMaster/nutritionMasterService';
import nutritionUnitMasterService from 'features/MasterDataManagement/NutritionUnitMaster/nutritionUnitMasterService';
import allergyMasterService from 'features/MasterDataManagement/AllergyMaster/allergyMasterService';
import {
  ALLERGY_MASTER_TYPE_SELECTSOURCE,
  DEFAULT_FILTER_ALLERGY_MASTER_TYPE,
} from 'features/MasterDataManagement/AllergyMaster/constants';
import { useAppSelector } from 'app/hooks';
import { selectUserInfo } from 'features/Auth/authSlice';
import { groupBy } from 'utils/group-by';
import { AllergyChartMaster } from '../../models';
import { Allergy, Nutrition } from 'features/CalorieManagement/MenuStructureSetting/models/MenuStructureSrttingList';
import { v4 as uuidv4 } from 'uuid';
import { formatPDFDisplayNumber } from 'features/PDF/components/common/formatPDFDisplayNumber';
import { KCAL_UNIT } from 'constants/kcalUnit';

const NEW = '新規';
const PENDING_CONFIRMATION = '確認待ち';
const PENDING_APPROVAL = '承認待ち';
const APPROVED = '承認済み';
const REJECTED = '';

const MAP_PRIVILEDGE: any = {
  [NEW]: 'new',
  [PENDING_CONFIRMATION]: 'wait_for_confirmed',
  [PENDING_APPROVAL]: 'wait_for_approval',
  [APPROVED]: 'admitted',
  [REJECTED]: 'none',
};

type DynamicObject = { [key: string]: any };

const PRIMARY_BUTTON_NUMBER = 0;

const CATEGORY_COLOR = [
  {
    id: PDF_BACKGROUND_COLOR.orange,
    title: PDF_BACKGROUND_COLOR.orange,
    value: PDF_BACKGROUND_COLOR.orange,
  },
  {
    id: PDF_BACKGROUND_COLOR.lightOrange,
    title: PDF_BACKGROUND_COLOR.lightOrange,
    value: PDF_BACKGROUND_COLOR.lightOrange,
  },
  {
    id: PDF_BACKGROUND_COLOR.red,
    title: PDF_BACKGROUND_COLOR.red,
    value: PDF_BACKGROUND_COLOR.red,
  },
  {
    id: PDF_BACKGROUND_COLOR.lightYellow,
    title: PDF_BACKGROUND_COLOR.lightYellow,
    value: PDF_BACKGROUND_COLOR.lightYellow,
  },
  {
    id: PDF_BACKGROUND_COLOR.white,
    title: PDF_BACKGROUND_COLOR.white,
    value: PDF_BACKGROUND_COLOR.white,
  },
  {
    id: PDF_BACKGROUND_COLOR.green,
    title: PDF_BACKGROUND_COLOR.green,
    value: PDF_BACKGROUND_COLOR.green,
  },
];

type ColWidthType = { type: string; menusObj: { [key: string]: boolean } };
const LONG_WIDTH = 2;
const CATEGORY_TYPE = 0;
const SPECIAL_TEXT = 1;

const CalorieAllergySettingEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isRemoveRef = useRef(false);
  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');
  const userInfo = useAppSelector(selectUserInfo);

  // contentsForEditPDF
  const [allergyChartDetail, setAllergyChartDetail] = useState<AllergyChartMaster>();
  const [charts, setCharts] = useState<DynamicObject[]>([]);
  const [remark, setRemark] = useState<string>('');
  const [date, setDate] = useState(new Date());
  const [categoryColor, setCategoryColor] = useState(CATEGORY_COLOR[0].value);
  const [selectedMenuIds, setSelectedMenuIds] = useState<any[]>([]);
  const [groupHeaders, setGroupHeaders] = useState<any[]>([]);
  const [headers, setHeaders] = useState([]);
  const [colWidthStyle, setColWidthStyle] = useState<ColWidthType[]>([]);
  const [isOpenMenuChartModal, setIsOpenMenuChartModal] = useState<boolean>(false);
  const [isOpenSaveChartModal, setIsOpenSaveChartModal] = useState<boolean>(false);
  const [nutritionsMaster, setNutritionsMaster] = useState<any>();
  const [allergiesMaster, setAllergiesMaster] = useState<any>();

  const allergyChartStatus = allergyChartDetail?.status;
  const verifierCd = allergyChartDetail?.applicant_cd;

  const isAllowedEdit = useIsAllowEdit({
    status: isEditMode ? MAP_PRIVILEDGE[allergyChartStatus || ''] : MAP_PRIVILEDGE[NEW],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const nutritionsRes = await fetchNutritionMaster();
        setNutritionsMaster(nutritionsRes);

        const allergiesRes = await fetchAllergyMaster();
        setAllergiesMaster(allergiesRes);

        const allergyChartDetailRes = await fetchDetailAllergyChart();
        setAllergyChartDetail(allergyChartDetailRes);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!allergyChartDetail?.allergy_chart_cd) return;

    let widthCols: ColWidthType[] = [];
    widthCols = mapWidthColStyleFromHeader(nutritionsMaster, allergiesMaster, widthCols);
    widthCols = mapWidthColStyleFromAllergyChartDetail(allergyChartDetail.allergy_charts, widthCols);

    const mappedMenu: any[] = mapMenuFromAllergyChartDetail(allergyChartDetail, widthCols);
    setCharts([...mappedMenu]);

    const { groupHeaders, headers } = mapHeaderGroupAndHeader(nutritionsMaster, allergiesMaster, widthCols);
    setGroupHeaders(groupHeaders);
    setHeaders(headers);

    const remark = allergyChartDetail.remarks || '';
    setRemark(remark);

    const revisionDate = allergyChartDetail.revision_date;
    setDate(revisionDate ? new Date(revisionDate) : new Date());

    setColWidthStyle([...widthCols]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allergyChartDetail]);

  // mapping
  const mapWidthColStyleFromHeader = (nutritionsRes: any, allergiesRes: any, widthCols: ColWidthType[]) => {
    let fieldIdx = 0;

    widthCols[fieldIdx] = {
      type: 'text',
      menusObj: {},
    };
    fieldIdx++;

    nutritionsRes?.forEach(() => {
      widthCols[fieldIdx] = {
        type: 'number',
        menusObj: {},
      };
      fieldIdx++;
    });

    allergiesRes?.forEach(() => {
      widthCols[fieldIdx] = {
        type: 'icon_mark',
        menusObj: {},
      };
      fieldIdx++;
    });

    return widthCols;
  };

  // map colWidth from NutritionAndAllergy, AllergyChart, AddedMenu
  // pass widthCols by reference
  const mapWidthColStyleFromNutritionAndAllergy = (item: any, startFieldIdx: number, widthCols: ColWidthType[]) => {
    let fieldIdx = startFieldIdx;

    item.nutritions
      // .sort((nutritionA: any, nutritionB: any) => nutritionA.display_order - nutritionB.display_order)
      .forEach(() => {
        widthCols[fieldIdx] = {
          type: 'number',
          menusObj: {},
        };
        fieldIdx++;
      });

    item.allergies
      // .sort(
      //   (allergyA: any, allergyB: any) =>
      //     allergyA.allergy_master?.type - allergyB.allergy_master?.type ||
      //     allergyA.allergy_master?.display_order - allergyB.allergy_master?.display_order
      // )
      .forEach((allergy: any) => {
        const colWidthType = allergy.allergy_item_master?.chart_col_width;
        const type =
          colWidthType === LONG_WIDTH || widthCols[fieldIdx]?.type === 'text_mark' ? 'text_mark' : 'icon_mark';
        const menusObj =
          colWidthType === LONG_WIDTH
            ? {
                ...widthCols[fieldIdx]?.menusObj,
                [item.menu_cd]: true,
              }
            : widthCols[fieldIdx]?.menusObj;

        if (item.menu_cd) {
          widthCols[fieldIdx] = {
            type: type,
            menusObj,
          };
        }

        fieldIdx++;
      });
  };

  const mapWidthColStyleFromAllergyChartDetail = (allergy_charts: any, widthCols: ColWidthType[]) => {
    allergy_charts.forEach((chart: any) => {
      const isCategoty = chart.display_type === CATEGORY_TYPE;

      if (isCategoty) return;
      let fieldIdx = 0;

      widthCols[fieldIdx] = {
        type: 'text',
        menusObj: {},
      };
      fieldIdx++;

      mapWidthColStyleFromNutritionAndAllergy(chart.menu_master, fieldIdx, widthCols);

      chart.menu_master.menu_composition_masters?.forEach((composition: any) => {
        let fieldIdx = 0;
        widthCols[fieldIdx] = {
          type: 'text',
          menusObj: {},
        };
        fieldIdx++;

        mapWidthColStyleFromNutritionAndAllergy(composition, fieldIdx, widthCols);
      });
    });

    return widthCols;
  };

  const mapWidthColStyleFromAddedMenu = (charts: any, widthCols: ColWidthType[]) => {
    charts.forEach((menu: any) => {
      let fieldIdx = 0;

      widthCols[fieldIdx] = {
        type: 'text',
        menusObj: {},
      };
      fieldIdx++;

      // if isChange true alway return true
      mapWidthColStyleFromNutritionAndAllergy(menu, fieldIdx, widthCols);

      menu.menu_composition_masters?.forEach((composition: any) => {
        let fieldIdx = 0;
        widthCols[fieldIdx] = {
          type: 'text',
          menusObj: {},
        };
        fieldIdx++;
        // if isChange true alway return true
        mapWidthColStyleFromNutritionAndAllergy(composition, fieldIdx, widthCols);
      });
    });

    return widthCols;
  };

  // map charts from NutritionAndAllergy, AllergyChart, AddedMenu
  const mapMenuFromNutritionAndAllergy = (
    item: any,
    newItem: any,
    startFieldIdx: number,
    widthCols: ColWidthType[]
  ) => {
    const mappedWidthCols = widthCols.map((item: ColWidthType) => {
      return {
        style: PDF_COL_STYLE_TABLE_BY_TYPE[item.type],
      };
    });
    let fieldIdx = startFieldIdx;
    item?.nutritions.forEach((nutrition: Nutrition) => {
      newItem['value'] = {
        ...newItem.value,
        [fieldIdx]: {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['number']['width'],
          },
          type: 'number',
          value:
            nutrition?.nutrition_master?.nutrition_unit_master === KCAL_UNIT
              ? formatPDFDisplayNumber(nutrition?.amount, 0)
              : formatPDFDisplayNumber(nutrition?.amount, 1),
        },
      };
      fieldIdx++;
    });

    item?.allergies.forEach((allergy: Allergy) => {
      const isTextMark = widthCols[fieldIdx].type === 'text_mark';
      const isSpecialText = allergy?.allergy_item_master?.pdf_format === SPECIAL_TEXT;

      const width = isTextMark
        ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['width']
        : otherColWidthCal(mappedWidthCols, remainWidthAfterSelection());
      const fontWeight = isTextMark ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['fontWeight'] : null;
      const specialStyle = isSpecialText ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['specialTextStyle'] : null;
      const type = isTextMark ? 'text_mark' : 'icon_mark';
      newItem['value'] = {
        ...newItem.value,
        [fieldIdx]: {
          style: {
            width: width,
            ...(fontWeight ? { fontWeight: fontWeight } : {}),
            ...(specialStyle ? { ...specialStyle } : {}),
          },
          type: type,
          value: `${allergy.allergy_item_master?.allergy_item_name ?? ''}`,
        },
      };
      fieldIdx++;
    });

    return newItem;
  };

  const mapMenuFromAllergyChartDetail = (allergyChart: AllergyChartMaster, widthCols: ColWidthType[]) => {
    const mappedMenus = allergyChart.allergy_charts.map((chart) => {
      const isCategoty = chart.display_type === CATEGORY_TYPE;
      if (isCategoty) {
        const id = uuidv4();
        return {
          category: true,
          title: chart.text,
          id: id,
          chartCode: chart.chart_cd,
          isHidden: !chart.display_flg,
          style: {
            backgroundColor: chart.background_color,
          },
        };
      } else {
        let fieldIdx = 0;
        const menuData = chart.menu_master;
        const isConfirm: boolean = chart.verifier_cd && isEditMode ? true : false;
        const isApprove: boolean = chart.approver_cd && isEditMode ? true : false;
        const isChange: boolean = menuData.change_flag ? true : false;
        const isOpenGroup: boolean = menuData.breakdown_display_flg;
        const displayGroups = chart.display_groups;
        const newMenu: any = {
          id: menuData.menu_cd,
          chartCode: chart.chart_cd,
          blank: chart.hierarchy,
          isHidden: !chart.display_flg,
          menuCode: menuData.menu_cd,
          code: menuData.menu_cd,
          name: menuData.menu_name,
          nutritions: menuData.nutritions,
          allergies: menuData.allergies,
          suspendDate: menuData.suspend_date,
          isConfirm,
          confirmerCode: isConfirm ? chart.verifier_cd : null,
          confirmerName: isConfirm ? chart.verifier_name : '',
          confirmDate: isConfirm ? chart.verified_date : null,
          isApprove,
          approverCode: isApprove ? chart.approver_cd : null,
          approverName: isApprove ? chart.approver_name : '',
          approveDate: isApprove ? chart.approved_date : null,
          isChange,
          isOpenGroup,
          displayGroups,
          value: {
            [fieldIdx]: {
              style: {
                width: PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'],
              },
              type: 'text',
              value: chart?.text ?? menuData.menu_name,
              blank: chart.hierarchy,
            },
          },
        };
        fieldIdx++;

        mapMenuFromNutritionAndAllergy(menuData, newMenu, fieldIdx, widthCols);

        newMenu['compositions'] = [];

        // map group display row
        const compositionGroupByDisplayGroup = groupBy(
          menuData.menu_composition_masters,
          ({ display_group_id }: any) => display_group_id
        );

        const displayGroupObj: any = {};
        displayGroups.forEach((displayGroup) => {
          displayGroupObj[displayGroup.display_group_id] = displayGroup.display_group_name;
        });

        Object.keys(compositionGroupByDisplayGroup).forEach((key: string) => {
          if (key === 'null') return;
          let fieldIdx = 0;
          const groupCompositions = compositionGroupByDisplayGroup[key];

          const clonedNutritionsMaster = cloneDeep(nutritionsMaster).map((nutrition: any) => {
            return {
              nutrition_master: nutrition,
            };
          });
          const clonedAllergiesMaster = cloneDeep(allergiesMaster).map((allergy: any) => {
            return {
              allergy_master: allergy,
            };
          });

          groupCompositions.forEach((composition: any, idx: number) => {
            composition.nutritions.forEach((nutrition: Nutrition) => {
              const nutritionCode = nutrition.nutrition_master?.nutrition_cd;
              const nutritionIndex = clonedNutritionsMaster.findIndex(
                (nutritionMaster: any) => nutritionMaster.nutrition_master.nutrition_cd === nutritionCode
              );
              const existedNutrition = clonedNutritionsMaster[nutritionIndex];
              if (existedNutrition && nutrition.amount !== undefined) {
                if (!existedNutrition?.amount) {
                  clonedNutritionsMaster[nutritionIndex] = {
                    ...existedNutrition,
                    amount: nutrition.amount,
                  };
                } else {
                  clonedNutritionsMaster[nutritionIndex]['amount'] += nutrition.amount;
                }
              }
            });

            composition.allergies?.forEach((allergy: Allergy, idx: number) => {
              const allergyCode = allergy?.allergy_master?.allergy_cd;
              const allergyIndex = clonedAllergiesMaster.findIndex(
                (allergyMaster: any) => allergyMaster.allergy_master.allergy_cd === allergyCode
              );
              const existedAllergy = clonedAllergiesMaster[allergyIndex];
              if (existedAllergy) {
                if (!existedAllergy?.allergy_item_master) {
                  clonedAllergiesMaster[allergyIndex] = allergy;
                } else {
                  if (
                    allergy?.allergy_item_master?.priority <
                    clonedAllergiesMaster[allergyIndex]?.allergy_item_master?.priority
                  ) {
                    clonedAllergiesMaster[allergyIndex] = allergy;
                  }
                }
              }
            });
          });

          const name = `Group-${key}`;
          const newDisplayGroup = {
            type: 'group',
            id: `${key}`,
            displayGroup: key,
            sortGroupId: `${key}0`,
            nutritions: clonedNutritionsMaster,
            allergies: clonedAllergiesMaster,
            value: {
              [fieldIdx]: {
                style: {
                  width: (PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'] || 0) / 2,
                },
                type: 'text',
                value: displayGroupObj[key] ?? name,
              },
            },
          };
          fieldIdx++;

          mapMenuFromNutritionAndAllergy(
            { nutritions: clonedNutritionsMaster, allergies: clonedAllergiesMaster },
            newDisplayGroup,
            fieldIdx,
            widthCols
          );
          newMenu['compositions'].push(newDisplayGroup);
        });

        // map composition row
        menuData.menu_composition_masters?.forEach((composition: any) => {
          const isChange: boolean = composition.change_flag ? true : false;
          let fieldIdx = 0;
          const newComposition = {
            type: 'composition',
            id: composition.composition_cd,
            code: composition.composition_cd,
            name: composition.composition_name,
            nutritions: composition.nutritions,
            allergies: composition.allergies,
            displayGroup: composition.display_group_id,
            sortGroupId: `${composition.display_group_id ?? 0}1`,
            isChange,
            value: {
              [fieldIdx]: {
                style: {
                  width: (PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'] || 0) / 2,
                },
                type: 'text',
                value: composition.composition_name,
                blank: chart.hierarchy,
              },
            },
          };
          fieldIdx++;

          mapMenuFromNutritionAndAllergy(composition, newComposition, fieldIdx, widthCols);

          newMenu['compositions'].push(newComposition);
        });

        newMenu['compositions'] = newMenu['compositions'].sort(
          (compositionA: any, compositionB: any) => compositionA.sortGroupId - compositionB.sortGroupId
        );

        return newMenu;
      }
    });

    return mappedMenus;
  };

  const mapMenuFromAddedMenu = (newMenus: any, widthCols: ColWidthType[], blank?: string) => {
    const mappedMenus = newMenus.map((menu: any, idx: number) => {
      let fieldIdx = 0;
      const isChange: boolean = menu.change_flag ? true : false;
      const isOpenGroup: boolean = menu.breakdown_display_flg;
      const newMenu: any = {
        isAdded: true,
        id: menu.menu_cd,
        menuCode: menu.menu_cd,
        code: menu.menu_cd,
        name: menu.menu_name,
        nutritions: menu.nutritions,
        allergies: menu.allergies,
        suspendDate: menu.suspend_date,
        isChange,
        isOpenGroup,
        blank,
        value: {
          [fieldIdx]: {
            style: {
              width: PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'],
            },
            type: 'text',
            value: menu.menu_name,
            blank,
          },
        },
      };
      fieldIdx++;

      mapMenuFromNutritionAndAllergy(menu, newMenu, fieldIdx, widthCols);

      newMenu['compositions'] = [];

      // map group display row
      const compositionGroupByDisplayGroup = groupBy(
        menu.menu_composition_masters,
        ({ display_group_id }: any) => display_group_id
      );

      Object.keys(compositionGroupByDisplayGroup).forEach((key: string) => {
        let fieldIdx = 0;
        if (key === 'null') return;
        const groupCompositions = compositionGroupByDisplayGroup[key];

        const clonedNutritionsMaster = cloneDeep(nutritionsMaster).map((nutrition: any) => {
          return {
            nutrition_master: nutrition,
          };
        });
        const clonedAllergiesMaster = cloneDeep(allergiesMaster).map((allergy: any) => {
          return {
            allergy_master: allergy,
          };
        });

        groupCompositions.forEach((composition: any, idx: number) => {
          composition.nutritions.forEach((nutrition: Nutrition) => {
            const nutritionCode = nutrition.nutrition_master?.nutrition_cd;
            const nutritionIndex = clonedNutritionsMaster.findIndex(
              (nutritionMaster: any) => nutritionMaster.nutrition_master.nutrition_cd === nutritionCode
            );

            const existedNutrition = clonedNutritionsMaster[nutritionIndex];
            if (existedNutrition) {
              if (!existedNutrition?.amount) {
                clonedNutritionsMaster[nutritionIndex] = {
                  ...existedNutrition,
                  amount: nutrition.amount,
                };
              } else {
                clonedNutritionsMaster[nutritionIndex]['amount'] += nutrition.amount;
              }
            }
          });

          composition.allergies?.forEach((allergy: Allergy) => {
            const allergyCode = allergy?.allergy_master?.allergy_cd;
            const allergyIndex = clonedAllergiesMaster.findIndex(
              (allergyMaster: any) => allergyMaster.allergy_master.allergy_cd === allergyCode
            );
            const existedAllergy = clonedAllergiesMaster[allergyIndex];
            if (existedAllergy) {
              if (!existedAllergy.allergy_item_master) {
                clonedAllergiesMaster[allergyIndex] = allergy;
              } else {
                if (
                  allergy?.allergy_item_master?.priority <
                  clonedAllergiesMaster[allergyIndex]?.allergy_item_master?.priority
                ) {
                  clonedAllergiesMaster[allergyIndex] = allergy;
                }
              }
            }
          });
        });

        const name = `Group-${key}`;
        const newDisplayGroup = {
          type: 'group',
          id: `${key}`,
          displayGroup: key,
          sortGroupId: `${key}0`,
          nutritions: clonedNutritionsMaster,
          allergies: clonedAllergiesMaster,
          value: {
            [fieldIdx]: {
              style: {
                width: (PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'] || 0) / 2,
              },
              type: 'text',
              value: name,
            },
          },
        };
        fieldIdx++;

        mapMenuFromNutritionAndAllergy(
          { nutritions: clonedNutritionsMaster, allergies: clonedAllergiesMaster },
          newDisplayGroup,
          fieldIdx,
          widthCols
        );
        newMenu['compositions'].push(newDisplayGroup);
      });

      // map composition row
      menu.menu_composition_masters?.forEach((composition: any) => {
        let fieldIdx = 0;
        const isChange: boolean = composition.change_flag ? true : false;
        const newComposition = {
          type: 'composition',
          id: composition.composition_cd,
          code: composition.composition_cd,
          name: composition.composition_name,
          nutritions: composition.nutritions,
          allergies: composition.allergies,
          sortGroupId: `${composition.display_group_id ?? 0}1`,
          isChange,
          value: {
            [fieldIdx]: {
              style: {
                width: (PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'] || 0) / 2,
              },
              type: 'text',
              value: composition.composition_name,
              blank,
            },
          },
        };
        fieldIdx++;

        mapMenuFromNutritionAndAllergy(composition, newComposition, fieldIdx, widthCols);

        newMenu['compositions'].push(newComposition);
      });

      newMenu['compositions'] = newMenu['compositions'].sort(
        (compositionA: any, compositionB: any) => compositionA.sortGroupId - compositionB.sortGroupId
      );

      return newMenu;
    });

    return mappedMenus;
  };

  const mapMenuFromChartMenu = (oldMenus: any[], widthCols: ColWidthType[]) => {
    const mappedNewMenus = oldMenus.map((menu: any) => {
      if (menu.category) return menu;
      let fieldIdx = 0;
      const newMenu: any = {
        ...menu,
      };
      fieldIdx++;

      mapMenuFromNutritionAndAllergy(menu, newMenu, fieldIdx, widthCols);

      newMenu['compositions'] = [];
      menu.compositions?.forEach((composition: any) => {
        let fieldIdx = 0;
        const newComposition = {
          ...composition,
        };
        fieldIdx++;

        mapMenuFromNutritionAndAllergy(composition, newComposition, fieldIdx, widthCols);

        newMenu['compositions'].push(newComposition);
      });

      return newMenu;
    });

    return mappedNewMenus;
  };

  // map header
  const mapSubGroupHeaders = (groupHeaders: GroupHeaderValueTypeInterface[], nutritions: any) => {
    const clonedGroupHeader = _.cloneDeep(groupHeaders);
    const caloriesGroupHeader = clonedGroupHeader.filter((item) => item.group === GROUP_HEADER_TYPE['calory'])[0];
    caloriesGroupHeader.subHeader = nutritions.map((nutrition: any) => {
      return {
        title: nutrition.nutrition_header_unit,
        style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
      };
    });
    return clonedGroupHeader;
  };

  const mapHeaderGroupAndHeader = (nutritionsRes: any, allergiesRes: any, widthCols: ColWidthType[]) => {
    let fieldIdx = 0;
    let headers: any = [];

    const nameCol = {
      title: 'メニュー名',
      group: GROUP_HEADER_TYPE['name'],
      style: PDF_COL_STYLE_TABLE_BY_TYPE['text'],
    };

    headers.push(nameCol);
    fieldIdx++;

    const mappedNutrition = nutritionsRes?.map((nutrition: any) => {
      fieldIdx++;
      return {
        title: nutrition.nutrition_name,
        group: GROUP_HEADER_TYPE['calory'],
        style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
      };
    });

    const mappedAllergy = allergiesRes?.map((allergy: any, idx: number) => {
      const type =
        allergy.type === ALLERGY_MASTER_TYPE_SELECTSOURCE[0].value
          ? GROUP_HEADER_TYPE['materials']
          : GROUP_HEADER_TYPE['equivalent_materials'];
      const styleName = widthCols[fieldIdx]?.type === 'text_mark' ? 'text_mark' : 'icon_mark';
      fieldIdx++;
      return {
        title: allergy.allergy_name,
        group: type,
        style: PDF_COL_STYLE_TABLE_BY_TYPE[styleName],
      };
    });

    headers = [...headers, ...(mappedNutrition || []), ...(mappedAllergy || [])];
    const mappedGroupHeaders = mapSubGroupHeaders(GROUP_HEADER, nutritionsRes);

    return {
      headers,
      groupHeaders: mappedGroupHeaders,
    };
  };

  // fetch
  const fetchDetailAllergyChart = async () => {
    try {
      const allergyChartDetailRes = await AllergyCaloryChartService.getDetail(id);
      return allergyChartDetailRes.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchNutritionMaster = async () => {
    try {
      const nutritionRes = await nutritionMasterService.getAll();
      const unitRes = await nutritionUnitMasterService.getAll();
      const res = nutritionRes.data.map((nutrition) => {
        return {
          ...nutrition,
          nutrition_unit: `(${
            unitRes.data.find((unit) => unit.nutrition_unit_cd === nutrition.nutrition_unit_master)?.nutrition_unit_name
          })`,
          nutrition_header_unit: `${
            unitRes.data.find((unit) => unit.nutrition_unit_cd === nutrition.nutrition_unit_master)?.nutrition_unit_name
          }`,
        };
      });
      return res;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchAllergyMaster = async () => {
    try {
      const allergyRes = await allergyMasterService.getAll({
        type: DEFAULT_FILTER_ALLERGY_MASTER_TYPE,
        displayFlag: true,
      });
      return allergyRes.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  // approve func
  const updateChartDataAPICall = async (payload: any) => {
    try {
      if (isEditMode) {
        // edit
        const res = await AllergyCaloryChartService.update(id, { ...payload });
        toast.success(res.message);
        const allergyChartDetailRes = await fetchDetailAllergyChart();
        setAllergyChartDetail(allergyChartDetailRes);
      } else {
        // create
        const res = await AllergyCaloryChartService.create(payload);
        toast.success(res.message);
        const newAllergyChartCode = res.data.allergy_chart_cd;
        history.push(`/calorie-management/calorie-allergy-chart-setting/edit/${newAllergyChartCode}`);
      }
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  const updateChartStatusAPICall = async (resource: ApproveButtonResource) => {
    let status = null;
    // next status after press approve button
    switch (resource) {
      case 'approval_request_btn':
        status = PENDING_APPROVAL;
        break;
      case 'approval_btn':
        status = APPROVED;
        break;
      case 'turn_back_btn':
        status = REJECTED;
        break;
      case 'confirmation_cancel_btn':
        status = REJECTED;
        break;
    }

    if (status === null) {
      console.error('error');
      return;
    }

    try {
      const res = await AllergyCaloryChartService.updateStatus(id, { status: status });
      toast.success(res.message);
      const allergyChartDetailRes = await fetchDetailAllergyChart();
      setAllergyChartDetail(allergyChartDetailRes);
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  // approve func
  const handleApproveClick = (resource: ApproveButtonResource, event: MouseEvent<HTMLElement>) => {
    if (resource === 'confirmation_request_btn') {
      handleSaveClick(PENDING_CONFIRMATION);
    } else {
      updateChartStatusAPICall(resource);
    }
  };

  const handleSaveClick = (status?: string) => {
    const mappedData = charts.map((chart: any, idx: number) => {
      const isConfirm = chart.isConfirm;
      const isApprove = chart.isApprove;
      const isCateGory = chart.category;
      let res = {};
      if (isCateGory) {
        res = {
          chart_cd: chart.chartCode,
          display_type: 0,
          display_order: idx,
          background_color: chart.style.backgroundColor,
          text: chart.title,
          display_flg: !chart.isHidden,
        };
      } else {
        const displayGroups = chart?.compositions
          .filter((composition: any) => composition.type === 'group' && composition.text !== undefined)
          .map((composition: any) => {
            return {
              display_group_id: composition.id,
              display_group_name: composition.text,
              chart_master: chart.chartCode,
            };
          });
        res = {
          chart_cd: chart.chartCode,
          text: chart.text,
          display_type: 1,
          display_order: idx,
          menu_master: chart.menuCode,
          hierarchy: chart.blank,
          display_flg: !chart.isHidden,
          verifier_cd: isConfirm ? chart.confirmerCode : null,
          approver_cd: isApprove ? chart.approverCode : null,
          display_groups: displayGroups ?? [],
        };
      }
      return res;
    });

    const dataRes = {
      change_flg: charts.some((chart) => chart.isAdded) || isRemoveRef.current || allergyChartDetail?.change_flg,
      business_format_master: allergyChartDetail?.business_format_master.business_format_cd,
      title: allergyChartDetail?.title,
      template: allergyChartDetail?.template,
      allergy_chart_cd: id,
      remarks: remark,
      revision_date: date,
      charts: mappedData,
      status: status,
    };
    updateChartDataAPICall(dataRes);
  };

  //window action
  const onWindowClick = useCallback((e: any) => {
    if (e.defaultPrevented) {
      return;
    }

    setSelectedMenuIds([]);
  }, []);

  /**
   * On window key down
   */
  const onWindowKeyDown = useCallback((e: any) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.key === 'Escape') {
      setSelectedMenuIds([]);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
    };
  }, [onWindowClick, onWindowKeyDown]);

  const toggleSelectionInGroup = (menuId: string) => {
    const index = selectedMenuIds.indexOf(menuId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setSelectedMenuIds([...selectedMenuIds, menuId]);

      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedMenuIds];
    shallow.splice(index, 1);

    setSelectedMenuIds(shallow);
  };

  const onClickRow = (e: any, record: any) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.button !== PRIMARY_BUTTON_NUMBER) {
      return;
    }

    // marking the event as used
    e.preventDefault();
    performAction(e, record);
  };

  /**
   * Was toggle in selection group key used
   * Determines if the platform specific toggle selection in group key was used
   */
  const wasToggleInSelectionGroupKeyUsed = (e: any) => {
    const isUsingWindows = true; // navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? e.ctrlKey : e.metaKey;
  };

  /**
   * Was multi select key used
   * Determines if the multiSelect key was used
   */
  const wasMultiSelectKeyUsed = (e: any) => e.shiftKey;

  const toggleSelection = (menuId: any) => {
    const wasSelected = selectedMenuIds.includes(menuId);

    const newTaskIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [menuId];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedMenuIds.length > 1) {
        return [menuId];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setSelectedMenuIds(newTaskIds);
  };

  /**
   * Perform action
   */
  const performAction = (e: any, record: any) => {
    // Ctrl
    if (wasToggleInSelectionGroupKeyUsed(e)) {
      toggleSelectionInGroup(record.id);
      return;
    }

    // Shift
    if (wasMultiSelectKeyUsed(e)) {
      const multiSlectedMenuIds = multiSelectTo({ data: charts, selectedItemIds: selectedMenuIds }, record.id) || [];
      setSelectedMenuIds([...multiSlectedMenuIds]);
      return;
    }

    // Escape
    if (e.key === 'Escape') {
      setSelectedMenuIds([]);
      return;
    }

    toggleSelection(record.id);
  };

  const handleDragStart = (start: any) => {
    if (!selectedMenuIds.includes(start.draggableId)) {
      setSelectedMenuIds([start.draggableId]);
    }
  };

  const handleDragEnd = (result: any) => {
    const source = result.source;
    const destination = result.destination;
    const droppableId = result.source.droppableId;

    if (!destination) return;

    // nothing to do
    if (!destination || result.reason === 'CANCEL') {
      // setDraggingMenuId(null);
      return;
    }

    let items = Array.from(charts);

    const reorderData = mutliDragAwareReorder({
      data: charts,
      selectedItemIds: selectedMenuIds,
      source,
      destination,
      droppableId,
    });
    items = reorderData.data;

    setCharts(items);
    // setDraggingMenuId(null);
  };

  const handleBeforeCapture = (start: any) => {
    const draggableId = start.draggableId;
    const selected = selectedMenuIds.find((id) => id === draggableId);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      setSelectedMenuIds([]);
    }

    // setDraggingMenuId(draggableId);
  };

  const mappedHeaders = mappingGroupHeaderStyleToHeader(headers, groupHeaders);
  const mappedGroupHeader = genWidthGroupHeader(mappedHeaders, groupHeaders);

  const handlePreviewPDF = async () => {
    const mappedHeadersRender = mappingGroupHeaderStyleToHeader(headers, groupHeaders, 100);
    const mappedGroupHeaderRender = genWidthGroupHeader(mappedHeadersRender, groupHeaders, 100);
    const mappedWidthCols = colWidthStyle.map((item: ColWidthType) => {
      return {
        style: PDF_COL_STYLE_TABLE_BY_TYPE[item.type],
      };
    });
    const clonedMenus = _.cloneDeep(charts);
    const mappedMenus = clonedMenus.map((chart: any) => {
      if (chart.category) return chart;
      let valueObj = chart.value;
      Object.keys(valueObj).forEach((key) => {
        if (valueObj[key].type === 'icon_mark') {
          chart['value'][key] = {
            ...chart['value'][key],
            style: {
              width: otherColWidthCal(mappedWidthCols),
            },
          };
        }
      });

      const compositions = chart?.compositions;
      chart['compositions'] = compositions.map((composition: any) => {
        let compositionValueObj = composition.value;
        Object.keys(compositionValueObj).forEach((key) => {
          if (compositionValueObj[key].type === 'icon_mark') {
            composition['value'][key] = {
              ...composition['value'][key],
              style: {
                width: otherColWidthCal(mappedWidthCols),
              },
            };
          }
        });

        return composition;
      });

      return chart;
    });

    const blob = await pdf(
      <CaloryPDF
        values={mappedMenus}
        headers={mappedHeadersRender}
        groupHeaders={mappedGroupHeaderRender}
        date={date.toISOString()}
        template={allergyChartDetail?.template}
      />
    ).toBlob();
    const url2 = URL.createObjectURL(blob);
    window.open(url2, '_blank');
  };

  const handleHiddenMenu = () => {
    const hiddenItems = charts.map((menu) => {
      if (selectedMenuIds.includes(menu.id)) {
        const hiddenStatus = charts.find((menu) => selectedMenuIds.includes(menu.id));
        return {
          ...menu,
          isHidden: !hiddenStatus?.isHidden,
        };
      } else {
        return menu;
      }
    });
    setCharts([...hiddenItems]);
  };

  const handleAddMenu = (newMenus: any, blank: any) => {
    let mappedChangedMenus: any[] = [];

    const widthCols = mapWidthColStyleFromAddedMenu(newMenus, colWidthStyle);
    const mappedMenus = mapMenuFromAddedMenu(newMenus, widthCols, blank);
    mappedChangedMenus = mapMenuFromChartMenu([...charts, ...mappedMenus], widthCols);

    // re-mapping header
    const { groupHeaders, headers } = mapHeaderGroupAndHeader(nutritionsMaster, allergiesMaster, widthCols);
    setGroupHeaders(groupHeaders);
    setHeaders(headers);

    setCharts([...mappedChangedMenus]);
    setColWidthStyle([...widthCols]);
    setIsOpenMenuChartModal(false);
  };

  const handleRemoveMenu = () => {
    let mappedChangedMenus: any[] = [];
    const remainMenus = charts.filter((menu) => !selectedMenuIds.includes(menu.id));
    let isChange = false;

    const widthCols = colWidthStyle.map((col: ColWidthType) => {
      let isMenusObjEmpty = false;
      selectedMenuIds.forEach((menuCode) => {
        if (col.menusObj[menuCode]) {
          delete col.menusObj[menuCode];
          isMenusObjEmpty = Object.keys(col.menusObj).length === 0 || isMenusObjEmpty;
        }
      });

      if (isMenusObjEmpty) {
        isChange = true;
      }

      return {
        ...col,
        type: isMenusObjEmpty ? 'icon_mark' : col.type,
      };
    });

    if (isChange) {
      mappedChangedMenus = mapMenuFromChartMenu(remainMenus, widthCols);

      // re-mapping header
      const { groupHeaders, headers } = mapHeaderGroupAndHeader(nutritionsMaster, allergiesMaster, widthCols);
      setGroupHeaders(groupHeaders);
      setHeaders(headers);
    }

    setCharts([...(isChange ? mappedChangedMenus : remainMenus)]);
    setColWidthStyle([...widthCols]);
    isRemoveRef.current = true;
  };

  const handleChangeGroupName = (e: any, selectedMenu: any, selectedComposition: any, key: any) => {
    const value = e.target.value;
    const menuIndex = charts.findIndex((chart) => chart.id === selectedMenu.id);
    const compositionIndex = charts[menuIndex].compositions.findIndex(
      (composition: any) => composition.id === selectedComposition.id
    );
    charts[menuIndex].compositions[compositionIndex] = {
      ...charts[menuIndex].compositions[compositionIndex],
      value: {
        ...charts[menuIndex].compositions[compositionIndex].value,
        [key]: {
          ...charts[menuIndex].compositions[compositionIndex].value[key],
          value,
        },
      },
      text: value,
    };

    setCharts([...charts]);
  };

  const handleChangeMenuName = (e: any, selectedMenu: any, key: any) => {
    const value = e.target.value;
    const menuIndex = charts.findIndex((menu) => menu.id === selectedMenu.id);
    charts[menuIndex] = {
      ...charts[menuIndex],
      value: {
        ...charts[menuIndex].value,
        [key]: {
          ...charts[menuIndex].value[key],
          value,
        },
      },
      text: value,
    };

    setCharts([...charts]);
  };

  const handleChangeCategoryTitle = (e: any, record: any) => {
    const value = e.target.value;
    const index = charts.findIndex((chart) => chart.id === record.id);
    charts[index] = {
      ...charts[index],
      title: value,
    };
    setCharts([...charts]);
  };

  const handleAddCategory = () => {
    const id = uuidv4();
    const newCategory = {
      category: true,
      id: id,
      style: { backgroundColor: categoryColor },
      title: '',
    };
    if (selectedMenuIds.length === 0) {
      setCharts([...charts, newCategory]);
      return;
    }
    const res = insertObject(charts, selectedMenuIds, newCategory, id);
    setCharts([...res]);
  };

  const insertObject = (charts: any, selectedMenuIds: any, newCategory: any, id: string) => {
    let result = [];

    for (let element of charts) {
      result.push(element);
      if (selectedMenuIds.includes(element.id)) {
        result.push({ ...newCategory, id: id });
      }
    }

    return result;
  };

  const handleAddOpenMenu = () => {
    setIsOpenMenuChartModal(true);
  };

  const handleCloseMenuChartModal = () => {
    setIsOpenMenuChartModal(false);
  };

  const handleSave = () => {
    setIsOpenSaveChartModal(true);
  };

  const handleCloseSaveChartModal = () => {
    setIsOpenSaveChartModal(false);
  };

  const handleChangeDate = (date: Date) => {
    setDate(date);
  };

  const getHeaderStyle = (header: any) => {
    if (header?.style?.manualHeader) {
      return <ManualTextNormal text={header.title} spaceWidth={header.style.width} />;
    }
    if (header?.style?.verticalHeader) {
      return <div style={{ writingMode: 'vertical-rl' }}>{header.title}</div>;
    }
    return <div>{header.title}</div>;
  };

  const handleApproveMenu = (e: any, record: any) => {
    const menuIndex = charts.findIndex((chart) => chart.id === record.id);
    const clonedMenus = cloneDeep(charts);
    const isApprove = !charts[menuIndex]?.isApprove;

    clonedMenus[menuIndex] = {
      ...clonedMenus[menuIndex],
      isApprove: isApprove,
      approverCode: isApprove ? userInfo?.employee_cd : null,
      approverName: isApprove ? userInfo?.employee_name : null,
      approveDate: new Date(),
    };

    setCharts([...clonedMenus]);
  };

  const handleConfirmMenu = (e: any, record: any) => {
    const menuIndex = charts.findIndex((chart) => chart.id === record.id);
    const clonedMenus = cloneDeep(charts);
    const isConfirm = !charts[menuIndex]?.isConfirm;

    clonedMenus[menuIndex] = {
      ...clonedMenus[menuIndex],
      isConfirm: isConfirm,
      confirmerCode: isConfirm ? userInfo?.employee_cd : null,
      confirmerName: isConfirm ? userInfo?.employee_name : null,
      confirmDate: new Date(),
    };

    setCharts([...clonedMenus]);
  };

  const handleRemarkChange = (e: any) => {
    setRemark(e.target.value);
  };

  return (
    <div
      style={{
        flex: 1,
        padding: '1rem 1rem',
        maxWidth: '100%',
      }}
    >
      {/* Modal */}
      {isOpenMenuChartModal &&
        createPortal(
          <AddMenuChartModal
            onClose={handleCloseMenuChartModal}
            onClickAdd={handleAddMenu}
            selectedMenuCodes={charts.map((menu) => menu.menuCode)}
          />,
          document.body
        )}
      {isOpenSaveChartModal &&
        createPortal(
          <NoteChartModal
            onClose={handleCloseSaveChartModal}
            onSave={handleSaveClick}
            remark={remark}
            onRemarkChange={handleRemarkChange}
          />,
          document.body
        )}

      {/* Header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('CalorieManagementSettingScreen.edit_head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <ApproveGroupButton
                status={isEditMode ? MAP_PRIVILEDGE[allergyChartStatus || ''] : MAP_PRIVILEDGE[NEW]}
                confirmUserId={verifierCd}
                onClick={handleApproveClick}
              />
              <Button onClick={history.goBack}>{t('MenuStructureSettingScreen.back')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      <div className="pb-3 pt-3 border-bottom">
        <Row>
          <Col xs="4"></Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleAddCategory} disabled={!isAllowedEdit}>
                {t('CalorieManagementSettingScreen.add_category')}
              </Button>
              <Form.Select
                className="category-select"
                style={{ width: '60px', backgroundColor: PDF_COLOR['background'][categoryColor] }}
                value={categoryColor}
                onChange={(e) => setCategoryColor(e.target.value)}
                disabled={!isAllowedEdit}
              >
                {CATEGORY_COLOR.map((option, idx) => {
                  return (
                    <option
                      key={idx}
                      value={option.value}
                      style={{ width: '20px', height: '20px', backgroundColor: PDF_COLOR['background'][option.title] }}
                    ></option>
                  );
                })}
              </Form.Select>
              <Button onClick={handleAddOpenMenu} disabled={!isAllowedEdit}>
                {t('CalorieManagementSettingScreen.add_menu')}
              </Button>
              <Button onClick={handleHiddenMenu} disabled={!isAllowedEdit}>
                {t('CalorieManagementSettingScreen.hide_menu')}
              </Button>
              <Button onClick={handleRemoveMenu} disabled={!isAllowedEdit}>
                {t('CalorieManagementSettingScreen.delete')}
              </Button>
              <Button onClick={handlePreviewPDF} disabled={!isAllowedEdit}>
                {t('CalorieManagementSettingScreen.preview')}
              </Button>
              <Button onClick={handleSave} disabled={!isAllowedEdit}>
                {t('CalorieManagementSettingScreen.save')}
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* PDF */}
      <div
        style={{
          width: '100%',
          padding: '20px 40px 90px 40px',
          fontWeight: 'normal',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '1600px' }}>
          <IntroductionFrame
            date={date}
            onDateChange={handleChangeDate}
            isAllowedEdit={isAllowedEdit}
            template={allergyChartDetail?.template}
          />
          <div style={{ marginBottom: '10px' }}></div>

          {/* Group Header */}
          <div>
            <div style={{ ...styles.row }}>
              <div style={{ width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%' }}></div>
              <div style={{ width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%' }}></div>
              {mappedGroupHeader.map((groupHeader: GroupHeaderValueTypeInterface, colIndex: number) => {
                return (
                  // GROUP HEADER
                  <div
                    style={{
                      ...styles.col,
                      ...styles.colGroupHeader,
                      ...{
                        width: groupHeader.style.width + '%',
                        backgroundColor: groupHeader.style.backgroundColor,
                      },
                      ...(colIndex === 0 ? styles.colEmptyGroupHeader : {}),
                    }}
                    key={colIndex}
                  >
                    <div>{groupHeader.title}</div>
                  </div>
                );
              })}
            </div>
            <div style={{ ...styles.row }}>
              <div style={{ width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%' }}></div>
              <div style={{ width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%' }}></div>
              {mappedGroupHeader.map((groupHeader: GroupHeaderValueTypeInterface, colIndex: number) => {
                if (groupHeader.subHeader) {
                  return groupHeader?.subHeader?.map((subHeader, subColIndex: number) => {
                    // SUB GROUP HEADER
                    return (
                      <div
                        style={{
                          ...styles.col,
                          ...styles.colSubGroupHeader,
                          ...(colIndex === 0 ? styles.colEmptyGroupHeader : {}),
                          ...{
                            width: subHeader.style.width + '%',
                            backgroundColor: groupHeader.style.backgroundColor,
                          },
                        }}
                        key={subColIndex}
                      >
                        <div>{subHeader.title}</div>
                      </div>
                    );
                  });
                } else {
                  return (
                    // EMPTY GROUP HEADER FOR SUBHEADER
                    <div
                      style={{
                        ...styles.col,
                        ...styles.colGroupHeader,
                        ...(colIndex === 0 || !groupHeader.subHeader ? styles.colEmptyGroupHeader : {}),
                        ...{
                          width: groupHeader.style.width + '%',
                          backgroundColor: groupHeader.style.backgroundColor,
                        },
                      }}
                      key={colIndex}
                    ></div>
                  );
                }
              })}
            </div>
          </div>

          {/* Header */}
          <div style={{ ...styles.row }}>
            <div style={{ width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%' }}></div>
            <div
              style={{ width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%', borderRight: 'solid 1px black' }}
            ></div>
            {mappedHeaders.map((header: HeaderValueTypeInterface, colIndex: number) => {
              return (
                // HEADER
                <div
                  style={{
                    ...styles.col,
                    ...styles.colHeader,
                    ...{
                      width: header.style.width + '%',
                      backgroundColor: header.style.backgroundColor,
                    },
                  }}
                  key={colIndex}
                >
                  {getHeaderStyle(header)}
                </div>
              );
            })}
          </div>

          {/* Info header */}
          <div
            style={{
              ...styles.row,
              width: '100%',
            }}
          >
            <div
              style={{
                ...styles.selectHeader,
                borderLeft: 'solid 1px black',
              }}
            >
              {t(`CalorieManagementSettingScreen.approval`)}
            </div>
            <div
              style={{
                ...styles.selectHeader,
              }}
            >
              {t(`CalorieManagementSettingScreen.confirmation`)}
            </div>
            <div
              style={{
                width: remainWidthAfterSelection() + '%',
                borderRight: 'solid 1px black',
                borderBottom: 'solid 1px black',
                borderTop: 'solid 1px black',
                backgroundColor: 'orange',
              }}
            ></div>
          </div>

          {/* Menu */}
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onBeforeCapture={handleBeforeCapture}
          >
            <Droppable droppableId="charts">
              {(provided) => (
                <div className="charts" {...provided.droppableProps} ref={provided.innerRef}>
                  {charts.map((record, index) => {
                    const isSelected = selectedMenuIds.some((id) => id === record.id);
                    return (
                      <Draggable key={record.id} draggableId={record.id} index={index} isDragDisabled={!isAllowedEdit}>
                        {(provided) => {
                          return (
                            <div
                              className="charts-dnd-container"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div onClick={(event) => onClickRow(event, record)} className="hehe">
                                {/* Menu */}
                                <div style={{ borderTop: 'solid 1px black' }}>
                                  {record.category ? (
                                    // CATEGORY
                                    <CategoryRow
                                      styles={styles}
                                      record={record}
                                      isSelected={isSelected}
                                      onChangeCategoryTitle={handleChangeCategoryTitle}
                                      isAllowedEdit={isAllowedEdit}
                                    />
                                  ) : (
                                    <MenuRow
                                      styles={styles}
                                      record={record}
                                      isSelected={isSelected}
                                      onApprove={handleApproveMenu}
                                      onConfirm={handleConfirmMenu}
                                      confirmUserId={verifierCd}
                                      revisionDate={date}
                                      onChangeMenuName={handleChangeMenuName}
                                      isAllowedEdit={isAllowedEdit}
                                    />
                                  )}
                                </div>

                                {/* Compositions */}
                                <div
                                  style={{
                                    ...(record.isHidden ? { backgroundColor: '#cccccc' } : {}),
                                  }}
                                >
                                  <MenuCompositionRow
                                    styles={styles}
                                    record={record}
                                    index={index}
                                    isSelected={isSelected}
                                    onChangeGroupName={handleChangeGroupName}
                                    revisionDate={date}
                                    isAllowedEdit={isAllowedEdit}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default CalorieAllergySettingEdit;
