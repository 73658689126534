import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../style.scss';
import { saveAs } from 'file-saver';

import CalorieTable from './components/calorieTable';
import { CaloryPDF } from 'features/PDF/components/CaloryPDF';
import { pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import AddAllergyChartModal from './components/addAllergyChartModal';
import { createPortal } from 'react-dom';
import {
  genWidthGroupHeader,
  GroupHeaderValueTypeInterface,
  mappingGroupHeaderStyleToHeader,
  otherColWidthCal,
} from 'features/PDF/components/api/api-header';
import { useDispatch } from 'react-redux';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import { useAppSelector } from 'app/hooks';
import { GROUP_HEADER } from 'features/PDF/constants/group-header';
import AllergyCaloryChartService from '../../allergyCalorieSettingService';
import nutritionMasterService from 'features/MasterDataManagement/NutritionMaster/nutritionMasterService';
import nutritionUnitMasterService from 'features/MasterDataManagement/NutritionUnitMaster/nutritionUnitMasterService';
import allergyMasterService from 'features/MasterDataManagement/AllergyMaster/allergyMasterService';
import {
  ALLERGY_MASTER_TYPE_SELECTSOURCE,
  DEFAULT_FILTER_ALLERGY_MASTER_TYPE,
} from 'features/MasterDataManagement/AllergyMaster/constants';
import { GROUP_HEADER_TYPE } from 'features/PDF/constants/type';
import { PDF_COL_STYLE_TABLE_BY_TYPE } from 'features/PDF/constants/style';
import { cloneDeep, groupBy } from 'lodash';
import dayjs from 'dayjs';
import { AllergyChartMaster } from '../../models';
import { v4 as uuidv4 } from 'uuid';
import { Allergy, Nutrition } from 'features/CalorieManagement/MenuStructureSetting/models/MenuStructureSrttingList';
import { formatPDFDisplayNumber } from 'features/PDF/components/common/formatPDFDisplayNumber';
import { KCAL_UNIT } from 'constants/kcalUnit';
import ReactDatePicker from 'react-datepicker';
import { InputFormDatePicker } from 'components/formfields/InputFormDatePicker';

interface Filter {
  showMoreDate: Date;
  applyShowMoreDate: boolean;
  business?: string;
}

const CATEGORY_TYPE = 0;
const SPECIAL_TEXT = 1;

const CalorieAllergySettingList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  // state
  const [filter, setFilter] = useState<Filter>({
    showMoreDate: new Date(),
    applyShowMoreDate: false,
    business: undefined,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isOpenAllergyChartModal, setIsOpenAllergyChartModal] = useState<boolean>(false);
  const [allergyChart, setAllergyChart] = useState<AllergyChartMaster[] | []>([]);

  const isDisableOutputButton = selectedIds.length === 0;

  const handleSelect = (id: number) => {
    if (selectedIds.includes(id)) {
      const newSelectedIds = selectedIds.filter((item) => item !== id);
      setSelectedIds([...newSelectedIds]);
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleCloseAllergyChartModal = () => {
    setIsOpenAllergyChartModal(false);
    fetchAllergyChartMaster();
  };

  // fetch
  const fetchAllergyChartMaster = async () => {
    try {
      if (filter.business === undefined) return;
      const res = await AllergyCaloryChartService.getAll(filter);
      setAllergyChart(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    if (businessFormatMasterList.length === 0) {
      dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessFormatMasterList.length === 0) return;
    setFilter({
      ...filter,
      business: businessFormatMasterList[0].business_format_cd,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessFormatMasterList]);

  useEffect(() => {
    fetchAllergyChartMaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchNutritionMaster = async () => {
    try {
      const nutritionRes = await nutritionMasterService.getAll();
      const unitRes = await nutritionUnitMasterService.getAll();
      const res = nutritionRes.data.map((nutrition) => {
        return {
          ...nutrition,
          nutrition_unit: `(${
            unitRes.data.find((unit) => unit.nutrition_unit_cd === nutrition.nutrition_unit_master)?.nutrition_unit_name
          })`,
          nutrition_header_unit: `${
            unitRes.data.find((unit) => unit.nutrition_unit_cd === nutrition.nutrition_unit_master)?.nutrition_unit_name
          }`,
        };
      });
      return res;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchAllergyMaster = async () => {
    try {
      const allergyRes = await allergyMasterService.getAll({
        type: DEFAULT_FILTER_ALLERGY_MASTER_TYPE,
        displayFlag: true,
      });
      return allergyRes.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchDetailAllergyChart = async (id: string) => {
    try {
      const allergyChartDetailRes = await AllergyCaloryChartService.getDetail(id);
      return allergyChartDetailRes.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const updateExportAllergyChart = async (id: string) => {
    try {
      const res = await AllergyCaloryChartService.updateExportPDF(id);
      toast.success(res.message);
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const nutritionsRes = await fetchNutritionMaster();
  //     setNutritionsMaster(nutritionsRes);

  //     const allergiesRes = await fetchAllergyMaster();
  //     setAllergiesMaster(allergiesRes);
  //   };

  //   fetchData();
  // }, []);

  // mapping
  const mapWidthColStyleFromHeader = (nutritionsRes: any, alldergiesRes: any, widthCols: string[]) => {
    let fieldIdx = 0;

    widthCols[fieldIdx] = 'text';
    fieldIdx++;

    nutritionsRes?.forEach(() => {
      widthCols[fieldIdx] = 'number';
      fieldIdx++;
    });

    alldergiesRes?.forEach(() => {
      widthCols[fieldIdx] = 'icon_mark';
      fieldIdx++;
    });

    return widthCols;
  };

  const mapWidthColStyleFromNutritionAndAllergy = (item: any, startFieldIdx: number, widthCols: string[]) => {
    let fieldIdx = startFieldIdx;

    item.nutritions
      // .sort((nutritionA: any, nutritionB: any) => nutritionA.display_order - nutritionB.display_order)
      .forEach(() => {
        widthCols[fieldIdx] = 'number';
        fieldIdx++;
      });

    item.allergies
      // .sort(
      //   (allergyA: any, allergyB: any) =>
      //     allergyA.allergy_master?.type - allergyB.allergy_master?.type ||
      //     allergyA.allergy_master?.display_order - allergyB.allergy_master?.display_order
      // )
      .forEach((allergy: any) => {
        const colWidthType = allergy.allergy_item_master?.chart_col_width;
        const type = colWidthType === 2 || widthCols[fieldIdx] === 'text_mark' ? 'text_mark' : 'icon_mark';
        widthCols[fieldIdx] = type;
        fieldIdx++;
      });
  };

  const mapWidthColStyleFromAllergyChartDetail = (allergy_charts: any, widthCols: string[]) => {
    allergy_charts.forEach((chart: any) => {
      const isCategoty = chart.display_type === 0;
      if (isCategoty) return;
      let fieldIdx = 0;

      widthCols[fieldIdx] = 'text';
      fieldIdx++;

      mapWidthColStyleFromNutritionAndAllergy(chart.menu_master, fieldIdx, widthCols);

      chart.menu_master.menu_composition_masters.forEach((composition: any) => {
        let fieldIdx = 0;
        widthCols[fieldIdx] = 'text';
        fieldIdx++;

        mapWidthColStyleFromNutritionAndAllergy(composition, fieldIdx, widthCols);
      });
    });

    return widthCols;
  };

  // map charts from NutritionAndAllergy, AllergyChart, AddedMenu
  const mapMenuFromNutritionAndAllergy = (item: any, newItem: any, startFieldIdx: number, widthCols: string[]) => {
    const mappedWidthCols = widthCols.map((item: string) => {
      return {
        style: PDF_COL_STYLE_TABLE_BY_TYPE[item],
      };
    });
    let fieldIdx = startFieldIdx;
    item?.nutritions.forEach((nutrition: Nutrition) => {
      newItem['value'] = {
        ...newItem.value,
        [fieldIdx]: {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['number']['width'],
          },
          type: 'number',
          value:
            nutrition?.nutrition_master?.nutrition_unit_master === KCAL_UNIT
              ? formatPDFDisplayNumber(nutrition?.amount, 0)
              : formatPDFDisplayNumber(nutrition?.amount, 1),
        },
      };
      fieldIdx++;
    });

    item?.allergies.forEach((allergy: Allergy) => {
      const isTextMark = widthCols[fieldIdx] === 'text_mark';
      const isSpecialText = allergy?.allergy_item_master?.pdf_format === SPECIAL_TEXT;

      const width = isTextMark ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['width'] : otherColWidthCal(mappedWidthCols);
      const fontWeight = isTextMark ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['fontWeight'] : null;
      const specialStyle = isSpecialText ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['specialTextStyle'] : null;
      const type = isTextMark ? 'text_mark' : 'icon_mark';
      newItem['value'] = {
        ...newItem.value,
        [fieldIdx]: {
          style: {
            width: width,
            ...(fontWeight ? { fontWeight: fontWeight } : {}),
            ...(specialStyle ? { ...specialStyle } : {}),
          },
          type: type,
          value: `${allergy.allergy_item_master?.allergy_item_name ?? ''}`,
        },
      };
      fieldIdx++;
    });

    return newItem;
  };

  const mapMenuFromAllergyChartDetail = (
    allergyChart: AllergyChartMaster,
    nutritionsMaster: any,
    allergiesMaster: any,
    widthCols: string[]
  ) => {
    const mappedMenus = allergyChart?.allergy_charts.map((chart) => {
      const isCategoty = chart.display_type === CATEGORY_TYPE;
      if (isCategoty) {
        const id = uuidv4();
        return {
          category: true,
          title: chart.text,
          id: id,
          chartCode: chart.chart_cd,
          isHidden: !chart.display_flg,
          style: {
            backgroundColor: chart.background_color,
          },
        };
      } else {
        let fieldIdx = 0;
        const menuData = chart.menu_master;
        const isChange: boolean = menuData.change_flag ? true : false;
        const isOpenGroup: boolean = menuData.breakdown_display_flg;
        const displayGroups = chart.display_groups;
        const newMenu: any = {
          id: menuData.menu_cd,
          chartCode: chart.chart_cd,
          blank: chart.hierarchy,
          isHidden: !chart.display_flg,
          menuCode: menuData.menu_cd,
          code: menuData.menu_cd,
          name: menuData.menu_name,
          nutritions: menuData.nutritions,
          allergies: menuData.allergies,
          suspendDate: menuData.suspend_date,
          isChange,
          isOpenGroup,
          displayGroups,
          value: {
            [fieldIdx]: {
              style: {
                width: PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'],
              },
              type: 'text',
              value: chart?.text ?? menuData.menu_name,
              blank: chart.hierarchy,
            },
          },
        };
        fieldIdx++;

        mapMenuFromNutritionAndAllergy(menuData, newMenu, fieldIdx, widthCols);

        newMenu['compositions'] = [];

        // map group display row
        const compositionGroupByDisplayGroup = groupBy(
          menuData.menu_composition_masters,
          ({ display_group_id }: any) => display_group_id
        );

        const displayGroupObj: any = {};
        displayGroups.forEach((displayGroup) => {
          displayGroupObj[displayGroup.display_group_id] = displayGroup.display_group_name;
        });

        Object.keys(compositionGroupByDisplayGroup).forEach((key: string) => {
          if (key === 'null') return;
          let fieldIdx = 0;
          const groupCompositions = compositionGroupByDisplayGroup[key];

          const clonedNutritionsMaster = cloneDeep(nutritionsMaster).map((nutrition: any) => {
            return {
              nutrition_master: nutrition,
            };
          });
          const clonedAllergiesMaster = cloneDeep(allergiesMaster).map((allergy: any) => {
            return {
              allergy_master: allergy,
            };
          });

          groupCompositions.forEach((composition: any, idx: number) => {
            composition.nutritions.forEach((nutrition: Nutrition) => {
              const nutritionCode = nutrition.nutrition_master?.nutrition_cd;
              const nutritionIndex = clonedNutritionsMaster.findIndex(
                (nutritionMaster: any) => nutritionMaster.nutrition_master.nutrition_cd === nutritionCode
              );
              const existedNutrition = clonedNutritionsMaster[nutritionIndex];
              if (existedNutrition && nutrition.amount !== undefined) {
                if (!existedNutrition?.amount) {
                  clonedNutritionsMaster[nutritionIndex] = {
                    ...existedNutrition,
                    amount: nutrition.amount,
                  };
                } else {
                  clonedNutritionsMaster[nutritionIndex]['amount'] += nutrition.amount;
                }
              }
            });

            composition.allergies?.forEach((allergy: Allergy, idx: number) => {
              const allergyCode = allergy?.allergy_master?.allergy_cd;
              const allergyIndex = clonedAllergiesMaster.findIndex(
                (allergyMaster: any) => allergyMaster.allergy_master.allergy_cd === allergyCode
              );
              const existedAllergy = clonedAllergiesMaster[allergyIndex];
              if (existedAllergy) {
                if (!existedAllergy?.allergy_item_master) {
                  clonedAllergiesMaster[allergyIndex] = allergy;
                } else {
                  if (
                    allergy?.allergy_item_master?.priority <
                    clonedAllergiesMaster[allergyIndex]?.allergy_item_master?.priority
                  ) {
                    clonedAllergiesMaster[allergyIndex] = allergy;
                  }
                }
              }
            });
          });

          const name = `Group-${key}`;
          const newDisplayGroup = {
            type: 'group',
            id: `${key}`,
            displayGroup: key,
            sortGroupId: `${key}0`,
            nutritions: clonedNutritionsMaster,
            allergies: clonedAllergiesMaster,
            value: {
              [fieldIdx]: {
                style: {
                  width: (PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'] || 0) / 2,
                },
                type: 'text',
                value: displayGroupObj[key] ?? name,
              },
            },
          };
          fieldIdx++;

          mapMenuFromNutritionAndAllergy(
            { nutritions: clonedNutritionsMaster, allergies: clonedAllergiesMaster },
            newDisplayGroup,
            fieldIdx,
            widthCols
          );
          newMenu['compositions'].push(newDisplayGroup);
        });

        // map composition row
        menuData.menu_composition_masters?.forEach((composition: any) => {
          const isChange: boolean = composition.change_flag ? true : false;
          let fieldIdx = 0;
          const newComposition = {
            type: 'composition',
            id: composition.composition_cd,
            code: composition.composition_cd,
            name: composition.composition_name,
            nutritions: composition.nutritions,
            allergies: composition.allergies,
            displayGroup: composition.display_group_id,
            sortGroupId: `${composition.display_group_id ?? 0}1`,
            isChange,
            value: {
              [fieldIdx]: {
                style: {
                  width: (PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'] || 0) / 2,
                },
                type: 'text',
                value: composition.composition_name,
                blank: chart.hierarchy,
              },
            },
          };
          fieldIdx++;

          mapMenuFromNutritionAndAllergy(composition, newComposition, fieldIdx, widthCols);

          newMenu['compositions'].push(newComposition);
        });

        newMenu['compositions'] = newMenu['compositions'].sort(
          (compositionA: any, compositionB: any) => compositionA.sortGroupId - compositionB.sortGroupId
        );

        return newMenu;
      }
    });

    return mappedMenus;
  };

  const mapSubGroupHeaders = (groupHeaders: GroupHeaderValueTypeInterface[], nutritions: any) => {
    // type === calory
    const clonedGroupHeader = cloneDeep(groupHeaders);
    const caloriesGroupHeader = clonedGroupHeader.filter((item) => item.group === GROUP_HEADER_TYPE['calory'])[0];
    caloriesGroupHeader.subHeader = nutritions.map((nutrition: any) => {
      return {
        title: nutrition.nutrition_header_unit,
        style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
      };
    });
    return clonedGroupHeader;
  };

  const mapHeaderGroupAndHeader = (nutritionsRes: any, alldergiesRes: any, widthCols: any) => {
    let fieldIdx = 0;
    let headers: any = [];

    const nameCol = {
      title: 'メニュー名',
      group: GROUP_HEADER_TYPE['name'],
      style: PDF_COL_STYLE_TABLE_BY_TYPE['text'],
    };

    headers.push(nameCol);
    fieldIdx++;

    const mappedNutrition = nutritionsRes?.map((nutrition: any) => {
      fieldIdx++;
      return {
        title: nutrition.nutrition_name,
        group: GROUP_HEADER_TYPE['calory'],
        style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
      };
    });

    const mappedAllergy = alldergiesRes?.map((allergy: any, idx: number) => {
      const type =
        allergy.type === ALLERGY_MASTER_TYPE_SELECTSOURCE[0].value
          ? GROUP_HEADER_TYPE['materials']
          : GROUP_HEADER_TYPE['equivalent_materials'];
      const styleName = widthCols[fieldIdx] === 'text_mark' ? 'text_mark' : 'icon_mark';
      fieldIdx++;
      return {
        title: allergy.allergy_name,
        group: type,
        style: PDF_COL_STYLE_TABLE_BY_TYPE[styleName],
      };
    });

    headers = [...headers, ...(mappedNutrition || []), ...(mappedAllergy || [])];
    const mappedGroupHeaders = mapSubGroupHeaders(GROUP_HEADER, nutritionsRes);

    return {
      headers,
      groupHeaders: mappedGroupHeaders,
    };
  };

  const downloadPDF = async () => {
    try {
      // toast.info(`${selectedIds.length} PDF ${selectedIds.length === 1 ? 'is' : 'are'} downloading!`);
      const allergiesRes = await fetchAllergyMaster();
      const nutritionsRes = await fetchNutritionMaster();
      for (let i = 0; i < selectedIds.length; i++) {
        const allergyChartDetailRes = await fetchDetailAllergyChart(String(selectedIds[i]));
        if (!allergyChartDetailRes?.allergy_chart_cd) return;

        let widthCols: string[] = [];
        widthCols = mapWidthColStyleFromHeader(nutritionsRes, allergiesRes, widthCols);
        widthCols = mapWidthColStyleFromAllergyChartDetail(allergyChartDetailRes?.allergy_charts, widthCols);

        const { groupHeaders, headers } = mapHeaderGroupAndHeader(nutritionsRes, allergiesRes, widthCols);
        const mappedMenus = mapMenuFromAllergyChartDetail(
          allergyChartDetailRes,
          nutritionsRes,
          allergiesRes,
          widthCols
        );
        const mappedHeadersRender = mappingGroupHeaderStyleToHeader(headers, groupHeaders, 100);
        const mappedGroupHeaderRender = genWidthGroupHeader(mappedHeadersRender, groupHeaders, 100);
        const mappedWidthCols = widthCols.map((item: string) => {
          return {
            style: PDF_COL_STYLE_TABLE_BY_TYPE[item],
          };
        });
        const clonedMenus = cloneDeep(mappedMenus);

        const calculatedWidthMenus = clonedMenus.map((chart: any) => {
          if (chart.category) return chart;
          let valueObj = chart.value;
          Object.keys(valueObj).forEach((key) => {
            if (valueObj[key].type === 'icon_mark') {
              chart['value'][key] = {
                ...chart['value'][key],
                style: {
                  width: otherColWidthCal(mappedWidthCols),
                },
              };
            }
          });

          const compositions = chart?.compositions;
          chart['compositions'] = compositions.map((composition: any) => {
            let compositionValueObj = composition.value;
            Object.keys(compositionValueObj).forEach((key) => {
              if (compositionValueObj[key].type === 'icon_mark') {
                composition['value'][key] = {
                  ...composition['value'][key],
                  style: {
                    width: otherColWidthCal(mappedWidthCols),
                  },
                };
              }
            });

            return composition;
          });

          return chart;
        });

        const asPdf = pdf();
        asPdf.updateContainer(
          <CaloryPDF
            values={calculatedWidthMenus}
            headers={mappedHeadersRender}
            groupHeaders={mappedGroupHeaderRender}
            date={allergyChartDetailRes?.revision_date}
            template={allergyChartDetailRes?.template}
          />
        );
        const pdfBlob = await asPdf.toBlob();
        saveAs(pdfBlob, `${allergyChartDetailRes?.title}_${dayjs(new Date()).format('YYYYMMDD')}.pdf`);
        toast.success(
          `PDF ${allergyChartDetailRes?.title}_${dayjs(new Date()).format('YYYYMMDD')} ${t(
            'CalorieManagementSettingScreen.downloaded'
          )}!`
        );
        await updateExportAllergyChart(String(selectedIds[i]));
      }

      fetchAllergyChartMaster();
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleRemoveChart = async (allergy_chart_cd: string) => {
    await AllergyCaloryChartService.delete(allergy_chart_cd);
    fetchAllergyChartMaster();
  };

  return (
    <div className="page-content d-flex flex-column">
      {/* Modal */}
      {isOpenAllergyChartModal &&
        createPortal(<AddAllergyChartModal onClose={handleCloseAllergyChartModal} />, document.body)}

      {/* Header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('CalorieManagementSettingScreen.list_head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={downloadPDF} disabled={isDisableOutputButton}>
                {t('CalorieManagementSettingScreen.output')}
              </Button>
              <Button onClick={() => setIsOpenAllergyChartModal(true)}>
                {t('CalorieManagementSettingScreen.new_register')}
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* Filter form  */}
      <div className="py-3">
        <Row className="align-items-center mb-3 gap-4">
          {/* Search, select filter  */}
          <Row>
            <Col xs="auto">
              <Row className="align-items-center">
                <Form.Label column className="text-start">
                  {t('MenuStructureSettingScreen.business')}
                </Form.Label>

                <Col xs="auto">
                  <Form.Select
                    style={{ width: '200px' }}
                    value={filter.business}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        business: e.target.value,
                      })
                    }
                  >
                    {businessFormatMasterList.map((option: any, idx: number) => {
                      return (
                        <option key={idx} value={option.business_format_cd} hidden={option?.hidden}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Col>

            <Col className="d-flex align-items-center">
              <Stack direction="horizontal" gap={2}>
                <Form.Check
                  className="anra-checkbox"
                  type="checkbox"
                  label={t('')}
                  checked={filter.applyShowMoreDate}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      applyShowMoreDate: !filter.applyShowMoreDate,
                    })
                  }
                />
                <div style={{ maxWidth: '120px' }}>
                  <ReactDatePicker
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    locale="ja"
                    selected={filter.showMoreDate}
                    onChange={(date: Date) => {
                      setFilter({
                        ...filter,
                        showMoreDate: date,
                      });
                    }}
                    customInput={<InputFormDatePicker />}
                    dateFormat="yyyy/MM/dd"
                    name="suspension_date"
                  />
                  {/* <DatePickerCustom
                    value={filter.showMoreDate}
                    onChange={(date: Date) => {
                      setFilter({
                        ...filter,
                        showMoreDate: date,
                      });
                    }}
                  /> */}
                </div>
                <div className="d-flex">{t(`CalorieManagementSettingScreen.show_more`)}</div>
              </Stack>
            </Col>
          </Row>
        </Row>
      </div>

      {/* Table */}
      <CalorieTable
        charts={allergyChart}
        onSelect={handleSelect}
        selectedIds={selectedIds}
        onRemove={handleRemoveChart}
      />
    </div>
  );
};

export default CalorieAllergySettingList;
