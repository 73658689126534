import { trim } from 'lodash';

export const SPECIFIC_RAW_MATERIALS = '特定原材料';
export const ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS = '特定原材料に準ずる品目';

export const NEW = '新規';
export const PENDING_CONFIRMATION = '確認待ち';
export const CONFIRMED = '確認済';
export const PENDING_APPROVAL = '承認待ち';
export const APPROVED = '承認済み';
export const SYSTEM_ID = '1035';

export enum AuthorityLevels {
  ADMIN = 999,
  APPROVAL = 200,
  NORMAL = 100,
}

export const validateField = (
  value: string | number | undefined | null,
  rules: { required?: boolean; pattern?: RegExp; min?: number; max?: number }
): string | null => {
  if (rules.required && isEmptyValue(value)) {
    return `この項目は必須です`;
  }

  if (rules.pattern && typeof value === 'string' && !rules.pattern.test(value)) {
    return `ゼロ以上の数字が必須·`;
  }

  if (typeof value === 'number') {
    if (rules.max !== undefined && value > rules.max) {
      return `this field must be less than or equal to ${rules.max}`;
    }

    if (rules.min !== undefined && value < rules.min) {
      return `this field must be greater than or equal to ${rules.min}`;
    }
  }

  return null;
};

const isEmptyValue = (value: any) =>
  value === undefined || value === null || (typeof value === 'string' && trim(value) === '');
