import { Page, Document, Font, StyleSheet } from '@react-pdf/renderer';
import { DateFrame } from './page-header/date-frame';
import { CaloryTable } from './page-body/table/table';
import { PageNumber } from './page-footer/page-number';
import { PDF_JAPAN_FONT } from '../constants/fonts';
import { CHART_TEMLATES } from 'features/CalorieManagement/CalorieAllergySetting/screens/Template';

Font.register({
  family: 'Japan',
  fonts: [
    PDF_JAPAN_FONT['extraLight'],
    PDF_JAPAN_FONT['light'],
    PDF_JAPAN_FONT['regular'],
    PDF_JAPAN_FONT['bold'],
    PDF_JAPAN_FONT['extraBold'],
    PDF_JAPAN_FONT['black'],
  ],
});

export const styles = StyleSheet.create({
  page: {
    width: '100%',
    padding: '20 40 90 40',
    fontFamily: 'Japan',
    fontWeight: 'normal',
    backgroundColor: 'white',
  },
});

export const CaloryPDF = ({ values, template, headers, groupHeaders, date = new Date() }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <DateFrame date={date} />
        {CHART_TEMLATES[template].exportComponent}
        <CaloryTable values={values} headers={headers} groupHeaders={groupHeaders} />
        <PageNumber />
      </Page>
    </Document>
  );
};
